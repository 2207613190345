import { TrashIcon } from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { groupBy } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getValChat } from "../../api";
import { ValChat } from "../../api/types";
import ErrorModal from "../ErrorModal";
import { parseAxiosError } from "../utils";

const PAGE_SIZE = 20;

type HistoryViewProps = {
  chatHistory: ValChat[];
  setChatHistory: Dispatch<SetStateAction<ValChat[]>>;
  historyPage: number;
  setHistoryPage: Dispatch<SetStateAction<number>>;
  totalPage: number;
  setTotalPage: Dispatch<SetStateAction<number>>;
  currentConservationId: number | null;
  onItemChanged: (historyId: number) => void;
  onItemDeleted: (historyId: number) => void;
  isMobileMode?: boolean;
  showAskVal: boolean;
};

export const HistoryView = ({
  chatHistory,
  historyPage,
  setHistoryPage,
  totalPage,
  setTotalPage,
  currentConservationId,
  setChatHistory,
  onItemChanged,
  onItemDeleted,
  isMobileMode = false,
  showAskVal,
}: HistoryViewProps) => {
  const [isLoadHisory, setIsLoadHisory] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  useEffect(() => {
    if (!isMobileMode && showAskVal && chatHistory.length === 0) {
      setIsLoadHisory(true);
      getValChat(historyPage, PAGE_SIZE)
        .then((response: any) => {
          if (response.data) {
            setChatHistory([...chatHistory, ...response.data.items]);
            setHistoryPage(response.data.page);
            setTotalPage(response.data.pages);
          }
        })
        .catch((error: AxiosError) => {
          setErrorMessage(parseAxiosError(error));
          setShowErrorModal(true);
        })
        .finally(() => setIsLoadHisory(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAskVal]);

  const loadMoreHistory = () => {
    if (historyPage < totalPage) {
      const newPage = historyPage + 1;
      setIsLoadHisory(true);
      getValChat(newPage, PAGE_SIZE)
        .then((response: any) => {
          if (response.data) {
            setChatHistory([...chatHistory, ...response.data.items]);
            setTotalPage(response.data.pages);
          }
        })
        .catch((error: AxiosError) => {
          setErrorMessage(parseAxiosError(error));
          setShowErrorModal(true);
        })
        .finally(() => setIsLoadHisory(false));
      setHistoryPage(newPage);
    }
  };

  const classifyHistory = (chatHistory: ValChat[]) => {
    let histories: any[] = [];

    chatHistory.forEach((history) => {
      let difference_In_Time =
        new Date(history.updated_at).getTime() - new Date().getTime();
      const days = Math.abs(
        Math.round(difference_In_Time / (1000 * 3600 * 24))
      );
      if (days === 0) {
        histories.push({
          ...history,
          period: 0,
        });
      } else if (days < 7) {
        histories.push({
          ...history,
          period: 7,
        });
      } else if (days < 30) {
        histories.push({
          ...history,
          period: 30,
        });
      } else {
        histories.push({
          ...history,
          period: format(new Date(history.updated_at), "MMM yyyy"),
        });
      }
    });
    const groupByHistory = groupBy(histories, "period");
    return groupByHistory;
  };

  const getPeriod = (period: string) => {
    switch (period) {
      case "0":
        return "Today";
      case "7":
        return "Previous 7 Days";
      case "30":
        return "Previous 30 Days";
      default:
        return period;
    }
  };

  const classifyChat =
    chatHistory.length > 0 ? classifyHistory(chatHistory) : {};

  return (
    <div className={`mx-2 flex flex-col px-3 pt-3 h-full`}>
      <div className="text-sm font-semibold">History</div>
      <div className="text-2xs flex flex-col gap-y-1 mt-2 overflow-y-auto show-scrollbar no-height">
        {Object.keys(classifyChat).length > 0 ? (
          <div className="flex flex-col gap-y-2">
            {Object.keys(classifyChat).map((key) => (
              <div key={key}>
                <div className="font-semibold text-2xs">{getPeriod(key)}</div>
                <div className="flex flex-col">
                  {classifyChat[key].map((history: ValChat) => (
                    <div
                      key={history.id}
                      className={`group flex flex-row gap-x-5 justify-between items-center hover:bg-gray-100 rounded hover:px-0.5 hover:cursor-pointer hover:text-black ${
                        currentConservationId === history.id &&
                        "bg-blue-100 text-blue-900"
                      }`}
                      onClick={() => onItemChanged(history.id)}
                    >
                      <div
                        className={`line-clamp-1 p-1 h-[23px] ${
                          currentConservationId === history.id &&
                          "font-semibold"
                        }`}
                      >
                        {history.last_response &&
                        history.last_response.trim().length > 0
                          ? history.last_response
                          : "No content"}
                      </div>
                      <div
                        id="delete-ai-chat-button"
                        className="hidden group-hover:block h-4 w-4 cursor-pointer"
                        onClick={() => onItemDeleted(history.id)}
                      >
                        <TrashIcon className="h-4 w-4 stroke-gray-500 hover:stroke-red-500" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          []
        )}
        {chatHistory.length > 0 && historyPage < totalPage && (
          <div className="flex flex-row justify-start mt-2">
            <button
              className="btn-primary px-3 py-1"
              disabled={historyPage === totalPage}
              onClick={() => loadMoreHistory()}
            >
              {isLoadHisory ? "Loading" : "Load More"}
            </button>
          </div>
        )}
      </div>

      <ErrorModal
        open={showErrorModal}
        setOpen={setShowErrorModal}
        errorMessage={errorMessage}
      />
    </div>
  );
};
